import React, { useState, createContext } from "react";

export const StateContext = createContext(true);

export const StateProvider = ({ children }) => {
  const [isDark, setTheme] = useState(true);
  return (
    <StateContext.Provider
      value={{
        isDark,
        setTheme,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
