exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-campaigns-index-js": () => import("./../../../src/pages/campaigns/index.js" /* webpackChunkName: "component---src-pages-campaigns-index-js" */),
  "component---src-pages-campaigns-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/campaigns/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-campaigns-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-disclosurestatements-js": () => import("./../../../src/pages/disclosurestatements.js" /* webpackChunkName: "component---src-pages-disclosurestatements-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-messages-js": () => import("./../../../src/pages/messages.js" /* webpackChunkName: "component---src-pages-messages-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-termsofservice-js": () => import("./../../../src/pages/termsofservice.js" /* webpackChunkName: "component---src-pages-termsofservice-js" */)
}

